import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

export interface Credentials {
  email: string;
  password: string;
  locale?: string;
  batch?: Batch;
}
export interface UserParams {
  id: number;
  batch?: Batch;
}

export interface UserListParams {
  search?: string;
  withRole?: Array<number>;
  withoutRole?: Array<number>;
  paginate?: false;
  batch?: Batch;
}

export interface ViewUserParams extends UserParams {
  with?: Array<string>;
}
export interface CreateUserParams {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  roles?: Array<number>;
  [key: string]: any;
  batch?: Batch;
}

export interface UpdateUserParams extends UserParams {
  first_name?: string;
  last_name?: string;
  password?: string;
  roles?: Array<number>;
  [key: string]: any;
}

export interface UpdateSelfParams {
  first_name?: string;
  last_name?: string;
  password: string;
  new_password: string;
  [key: string]: any;
  batch?: Batch;
}
export interface FBLogin {
  authToken: string;
}
export interface GoogleLogin {
  authToken: string;
}

interface RequestResetParams {
  email: string;
  batch?: Batch;
}

interface ResetTokenCheckParams {
  token: string;
  batch?: Batch;
}

interface ResetParams {
  token: string;
  password: string;
  password_confirmation: string;
  batch?: Batch;
}

interface LogoutParams {
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private api: ApiCallService) { }

  public login(params: Credentials) {
    return this.api.call('access.user.auth', params);
  }
  public logout(params?: LogoutParams) {
    return this.api.call('access.user.logout');
  }
  public list(params?: UserListParams, page?: number) {
    return this.api.call('access.user.list', params, page);
  }
  public view(params: ViewUserParams) {
    return this.api.call('access.user.view', params);
  }
  public update(params: UpdateUserParams) {
    return this.api.call('access.user.update', params);
  }
  public viewSelf(params?: { batch?: Batch }) {
    return this.api.call('access.user.viewSelf', params);
  }
  public updateSelf(params: UpdateSelfParams) {
    return this.api.call('access.user.updateSelf', params);
  }
  public create(params: CreateUserParams) {
    return this.api.call('access.user.store', params);
  }
  public delete(params: UserParams) {
    return this.api.call('access.user.delete', params);
  }
  public checkSession(params?: { batch?: Batch }) {
    return this.api.call('access.user.checkSession');
  }
  public requestReset(params: RequestResetParams) {
    return this.api.call('access.user.requestReset', params);
  }
  public resetTokenCheck(params: ResetTokenCheckParams) {
    return this.api.call('access.user.resetTokenCheck', params);
  }
  public reset(params: ResetParams) {
    return this.api.call('access.user.reset', params);
  }
  public fbLogin(params: FBLogin) {
    return this.api.call('facebook.facebook.login', params);
  }
  public googleLogin(params: GoogleLogin) {
    return this.api.call('google.google.login', params);
  }
}
