import { Injectable } from '@angular/core';
import { ApiCallService } from '../api-call.service';
import { ImagesService } from '../images/images.service';

interface ListRelationParams {
  id?: number;
  slug?: string;
  object: string;
  relatedObject: string;
  with?: string[];
  paginate?: boolean;
  limit?: number;
  relatedBy: object;

}

interface SaveRelationParams {
  objectId: number;
  relatedObjectId: number;
  object: string;
  relatedObject: string;

}

interface DeleteRelationParams {
  id: number;
  object: string;
  relatedObject: string;

}

@Injectable({
  providedIn: 'root'
})
export class RelationService extends ImagesService {
  protected module = 'relation';
  protected controller = 'relation';

  constructor(api: ApiCallService) {
    super(api);
  }

  public list(params: ListRelationParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.list',
      params
    );
  }

  public save(params: SaveRelationParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.save',
      params
    );
  }

  public delete(params: DeleteRelationParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.delete',
      params
    );
  }
}
