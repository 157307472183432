import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ISlotComponent } from '../../slot/slot-component';
import { ApiService } from '../../../backbone/api.service';
import { LanguageService } from '../../../backbone/language.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { combineLatestWith, debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { EventBusService } from '../../../backbone/event-bus.service';
import { Event } from '../../../backbone/event.class';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { PermissionsService } from '../../../backbone/permissions.service';
import { QueryService } from '../../../backbone/query.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { TransformService } from '../../../backbone/transform.service';
import { UntypedFormGroup } from '@angular/forms';
import { EvalService } from '../../../backbone/eval.service';
import { CommunicationService, Message } from '../../../backbone/communication.service';


@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  providers: [GetArrayPathPipe]
})
export class TableListComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;
  @Input() public parentForm: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private subsc: Subscription[] = [];
  private dataService;
  private urlParams;
  public willLoad = false;

  cols: Array<string> = [];
  rows: Array<any>;
  filteredRows: Array<any>;
  length: number;
  pageSize: number;
  pageIndex = 1;
  rowSelection = [];
  checkbox: object = {};
  mySubscription: any;
  listedColumns: any = [];
  actionColAppended = false;

  private destroyed = new Subject<void>();

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private eventBus: EventBusService,
    public language: LanguageService,
    private permissionSevice: PermissionsService,
    public query: QueryService,
    private router: Router,
    private getArrayPath: GetArrayPathPipe,
    public transformData: TransformService,
    public route: ActivatedRoute,
    public evaluator: EvalService,
    private comm: CommunicationService
  ) {
    this.query = query;
  }

  ngOnInit() {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    if (typeof this.data.actions !== "undefined") {
      this.data.actions.forEach((action, key) => {
        if (!this.permissionSevice.checkPermissions(action)) {
          this.data.actions[key].denied = true;
        } else {
          this.data.actions[key].denied = false;
        }
      });
      
      for (const action of this.data.actions) {
        if (action.actionBar && !action.denied) {
          // register action in action bar(s)
          if (typeof action.mutationIndex !== 'undefined'
            && typeof this.data.dataSource.params !== 'undefined'
            && typeof this.data.dataSource.params.mutations !== 'undefined'
          ) {
            action.actionBar.data.value
              = this.data.dataSource.params.mutations[action.mutationIndex];
          }
          action.event = action.id + '_tableList_' + this.data.instanceId;
          this.eventBus.fire(new Event('addToActionBar', action));
          this.subsc.push(this.eventBus.on(action.event, (actionData) => {
            if (typeof this[actionData.id] !== 'undefined') {
              this[actionData.id](actionData);
            }
          }));
        }
      }
    }


    // Refresh data if query params or url param has been changed
    combineLatest([
      this.route.queryParams.pipe(startWith(null)),
      this.route.params.pipe(startWith(null))
    ]).pipe(takeUntil(this.destroyed), debounceTime(0))
      .subscribe((params) => {
        const urlParams = JSON.parse(JSON.stringify(params));
        if (
          typeof this.paginator !== 'undefined'
          && (typeof urlParams[0].page === 'undefined' || !urlParams[0].page)
        ) {
          urlParams[0].page = '1';
          this.paginator.firstPage();
        }
        if (
          this.urlParams
          && JSON.stringify(this.urlParams) !== JSON.stringify(urlParams)
        ) {
          this.load();
        }
        this.urlParams = urlParams;
      });
    if (this.data.loadOnInit === false && Object.keys(this.query.queryUrl).length === 0) {
      return;
    }
    this.load();
  }

  load(queryParams = null, page = null) {
    this.willLoad = true;
    this.query.updateQueryUrl(this.route.snapshot);
    this.cols = [];
    if (typeof this.data.listedColumns !== 'undefined') {
      this.listedColumns = [...this.data.listedColumns];
      this.listedColumns.forEach((item, key) => {
        this.cols.push(item.label || item.id);
      });
    }
    if (this.data.selectable) {
      this.cols.unshift('select');
    }

    // prepare actions
    if (this.cols.indexOf('action') <= 0 && this.data.actions) {
      this.actionColAppended = true;
      this.cols.push('action');
    }

    if (!page && typeof this.route.snapshot.queryParams.page !== 'undefined') {
      // Set paging to query if comes from url
      page = this.route.snapshot.queryParams.page;
    }
    let params: any = {};

    if (!queryParams) {
      queryParams = this.query.prepareParams();
    }
    if (typeof this.data?.dataSource?.params !== 'undefined') {
      params = { ...this.data.dataSource.params };

      // If params has dynamic params from route url - search and replace them
      this.route.params.pipe(take(1)).subscribe(urlParams => {
        const stringParams = JSON.stringify(params);
        let replaced = stringParams;
        for (const key of Object.keys(urlParams)) {
          const search = ':' + key;
          replaced = replaced.replace(new RegExp(search, 'g'), urlParams[key]);
        }
        if (replaced !== '') {
          params = { ...JSON.parse(replaced) };
        }
      });
      if (typeof this.parentForm !== 'undefined') {
        let replaced = JSON.stringify(params);
        const rootForm = this.parentForm.root as UntypedFormGroup;
        const formValues = rootForm.getRawValue();
        for (const p in formValues) {
          if (typeof formValues[p] !== 'undefined') {
            replaced = replaced.replace('{' + p + '}', formValues[p]);
          }
        }
        if (replaced !== '') {
          params = { ...JSON.parse(replaced) };
        }
      }
      if (Object.keys(queryParams).length > 0) {
        if (
          typeof params.mutations !== 'undefined'
          && typeof queryParams.mutations !== 'undefined'
        ) {
          const cloned = [...params.mutations];
          params = { ...params, ...queryParams };
          params.mutations = this.query.mergeMutations(cloned, queryParams.mutations);
        } else {
          params = { ...params, ...queryParams };
        }
      }
    } else if (Object.keys(queryParams).length > 0) {
      params = queryParams;
    }

    let apiSource: any;
    if (this.data.dataSource) {
      this.dataService = this.api.getService(this.data.dataSource.service);
    }

    apiSource = (this.dataService &&
      this.dataService[this.data.dataSource.method](params, page)) || of(null);

    // apiSource - data for the table comes from an api call
    // this.data.dataObject - data for the table comes from parent component
    apiSource.pipe(
      combineLatestWith(of(this.data.dataObject).pipe(map(data => {
        return {
          result: {
            data
          }
        };
      }))),
      take(1),
      map(res => {
        if (res[0]) {
          return res[0];
        } else {
          return res[1];
        }
      })
    )
    // combineLatest([apiSource,
    //   of(this.data.dataObject).pipe(map(data => {
    //     return {
    //       result: {
    //         data
    //       }
    //     };
    //   }))])
    //   .pipe(
    //     take(1),
    //     map(res => {
    //       if (res[0]) {
    //         return res[0];
    //       } else {
    //         return res[1];
    //       }
    //     }))
      .subscribe((response) => {
        const rowKey: any = this.data.dataSource?.rowsResponseKey ||
          this.data.rowsResponseKey;

        if (typeof rowKey !== 'undefined') {
          this.rows = (response as any).result.data[rowKey];
        } else {
          this.rows = (response as any).result.data;
        }
        // Add extracted columns directly to the table columns in header and rows;
        if (typeof this.data.extractedColumns !== 'undefined'
          && typeof this.rows[0] !== 'undefined'
        ) {
          /* Define empty columns on every request
          /* so they can be updated from the response
          */
          const cols = [];
          const listedCols = [];

          // Loop through the configuration of the columns for extraction
          for (const extracted of this.data.extractedColumns) {
            const extractedPath = this.getArrayPath.transform(
              this.rows[0],
              extracted.extract
            );

            // tslint:disable-next-line: forin
            for (const col in extractedPath) {
              if (!isNaN(Number(col))) {
                throw new Error('Extract must be an object not an array');
              }
              const path = [];
              let components = [];
              let extractPath;
              if (typeof extracted.extract === 'string') {
                extractPath = [extracted.extract];
              } else {
                extractPath = extracted.extract;
              }
              if (extracted.path !== '') {
                // if path is specified use it in extracted column path
                path.push(...extracted.extract, col, extracted.path);
                if (extracted.components) {
                  components = [...extracted.components];
                }
              } else {
                // if path is not specified extracted column path should also be empty
                // so that it will receive the entire row
                if (extracted.components) {
                  // if extracted column should render components, add the column
                  // path to the component path so it can find its value
                  for (const component of extracted.components) {
                    const clonedComponent = JSON.parse(JSON.stringify(component));
                    if (typeof clonedComponent.data.path === 'string') {
                      clonedComponent.data.path = [clonedComponent.data.path];
                    }
                    clonedComponent.data.path = [
                      ...extracted.extract,
                      col,
                      ...clonedComponent.data.path
                    ];

                    // if component has actions walk them and adjust paths
                    if (clonedComponent.data.events) {
                      const events = clonedComponent.data.events;
                      // tslint:disable-next-line: forin
                      for (const event in events) {
                        // tslint:disable-next-line: forin
                        for (const action in events[event]) {
                          if (typeof events[event][action].path === 'undefined') {
                            events[event][action].path = [
                              ...extracted.extract,
                              col
                            ];
                          } else {
                            if (typeof events[event][action].path === 'string') {
                              events[event][action].path = [events[event][action].path];
                            }
                            events[event][action].path = [
                              ...extracted.extract,
                              col,
                              ...events[event][action].path
                            ];
                          }
                          if (typeof events[event][action].params !== 'undefined') {
                            // tslint:disable-next-line: forin
                            for (const param in events[event][action].params) {
                              if (events[event][action].params[param] === '{column}') {
                                events[event][action].params[param] = col;
                              }
                              if (
                                typeof events[event][action].params[param] === 'object'
                                && typeof events[event][action].params[param]['{column}'] !== 'undefined'
                              ) {
                                events[event][action].params[param][col] =
                                  events[event][action].params[param]['{column}'];
                                delete events[event][action].params[param]['{column}'];
                              }
                            }
                          }
                        }
                      }
                    }
                    components.push(clonedComponent);
                  }
                }
              }

              // add extracted column to table columns
              const listedCol: { [key: string]: any } = {
                path,
                label: col,
                sort: extracted.sort,
                extract: true
              };
              if (components.length > 0) {
                listedCol.components = components;
              }
              listedCols.push(listedCol);
              cols.push(col);
            }
          }

          this.cols = [...this.cols, ...cols];
          this.listedColumns = [...this.listedColumns, ...listedCols];
        }

        // tslint:disable-next-line: forin
        for (const i in this.rows) {
          this.rowSelection[i] = false;
          this.checkbox[i] = {
            color: 'primary',
            checked: false,
            indeterminate: false,
            disabled: false
          };
        }

        if ((response as any).result.meta) {
          this.length = (response as any).result.meta.total;
          this.pageSize = (response as any).result.meta.per_page;
        }

        this.applyFilter([]);
      });
  }

  rowNgClass(row) {
    const classes = [];
    if (row && typeof this.data.rowConditionalClasses !== 'undefined') {
      for (const condition of this.data.rowConditionalClasses) {
        if (this.evaluator.exec(row, condition)) {
          classes.push(condition.class);
        }
      }
    }
    return classes;
  }

  sort(column = null, clear = false) {
    if (column) {
      this.query.updateQueryUrl(this.route.snapshot);

      const mutations: any = [];
      const params = [];
      params.push(column.path);
      if (!clear) {
        params.push([this.query.getSortDirection(column.path, column)]);
      }

      mutations.push({
        constraint: column.constraint,
        params
      });

      const queryParams = this.query.prepareQueryParams(
        'sort',
        { mutations }
      );

      this.router.navigate([], {
        queryParams
      }).then(() => {
        this.query.updateQueryUrl(this.route.snapshot);
      });
    } else {
      this.router.navigate([]).then(() => {
        this.query.updateQueryUrl(this.route.snapshot);
      });
    }
  }

  selectRow(index) {
    if (this.rowSelection[index] === true) {
      this.rowSelection[index] = false;
    } else {
      this.rowSelection[index] = true;
    }
  }

  selectAllRows(params: any) {
    // tslint:disable-next-line: forin
    for (const i in this.rowSelection) {
      this.rowSelection[i] = params.checked;
      this.checkbox[i].checked = params.checked;
    }
  }

  reset() {
    const queryParams = this.query.prepareQueryParams('mutate');
    this.router.navigate([], { queryParams })
      .then(() => {
        this.query.updateQueryUrl(this.route.snapshot);
      });
  }

  mutate(params: any) {
    if (typeof params.actionParams !== 'undefined'
      && Object.keys(params.actionParams).length > 0
    ) {
      const queryParams = this.query.prepareQueryParams(
        params.id,
        params.actionParams
      );
      if (queryParams[params.id] !== '') {
        this.router.navigate([], {
          queryParams
        }).then(() => {
          this.query.updateQueryUrl(this.route.snapshot);
        });
      }
    }
  }

  delete(params, rowIndex?: number) {
    if (typeof rowIndex !== 'undefined' || this.rowSelection.indexOf(true) >= 0) {
      const alertDialog: MatDialogRef<any> = this.dialog.open(AlertDialogComponent,
        {
          data: {
            title: this.language.getLabel(params.confirmDialog.title),
            text: this.language.getLabel(params.confirmDialog.text),
            button: this.language.getLabel(params.label)
          }
        }
      );
      alertDialog.afterClosed().pipe(take(1)).subscribe((result: string) => {
        if (result === 'confirm') {
          if (typeof rowIndex !== 'undefined') {
            this.api.getService(params.apiCall.service)[params.apiCall.method]({
              id: this.rows[rowIndex].id
            })
              .pipe(take(1))
              .subscribe(() => {
                this.rows.splice(rowIndex, 1);
                this.table.renderRows();
              });
          } else {
            const selectedRows = this.rowSelection.filter(val => val === true);
            if (selectedRows.length > 0) {
              let i = 1;
              const firstIndex = this.rowSelection.indexOf(true);
              while (this.rowSelection.indexOf(true) >= 0) {
                const index = this.rowSelection.indexOf(true);
                if (i === 1 && selectedRows.length > 1) {
                  this.api.getService(params.apiCall.service)[params.apiCall.method]({
                    id: this.rows[index].id,
                    batch: {
                      name: 'delete'
                    }
                  });
                } else if (i === selectedRows.length && selectedRows.length > 1) {
                  this.api.getService(params.apiCall.service)[params.apiCall.method]({
                    id: this.rows[index].id,
                    batch: {
                      name: 'delete',
                      stage: 'finish'
                    }
                  })
                    .pipe(take(1))
                    .subscribe(() => {
                      this.rows.splice(firstIndex, selectedRows.length);
                      this.table.renderRows();
                    });
                } else if (i > 1 && i !== selectedRows.length) {
                  this.api.getService(params.apiCall.service)[params.apiCall.method]({
                    id: this.rows[index].id,
                    batch: {
                      name: 'delete'
                    }
                  });
                } else {
                  this.api.getService(params.apiCall.service)[params.apiCall.method]({
                    id: this.rows[index].id
                  }).pipe(take(1))
                    .subscribe(() => {
                      this.rows.splice(firstIndex, selectedRows.length);
                      this.table.renderRows();
                    });
                }
                this.rowSelection[index] = false;
                this.checkbox[index].checked = false;
                i++;
              }
            }
          }
        }
      });
    }
  }
  rowClick(event) {
    const target = event.target as HTMLElement;
    const row = target.closest('tr');
    const rowClick = row.getElementsByClassName('on-row-click');
    if (rowClick.length > 0) {
      const clickTarget = rowClick[0] as HTMLElement;
      clickTarget.click();
    }
  }
  handlePaginator(e: PageEvent) {
    const page = e.pageIndex + 1;
    this.router.navigate([], { queryParams: { page }, queryParamsHandling: 'merge' })
      .then(() => {
        this.query.updateQueryUrl(this.route.snapshot);
      });
  }

  applyFilter(paramsArray: any) {
    if (typeof paramsArray === 'undefined' ||
      !paramsArray.length) {
      this.filteredRows = this.rows;
      return;
    }

    this.filteredRows = this.rows.filter(row => {
      const isFound = [];

      for (const params of paramsArray) {

        const prop = (params as any).data;
        const op = (params as any).op;
        const value = (params as any).value;
        const path = (params as any).path || [];

        if (row.hasOwnProperty(prop) &&
          this.evaluator.exec(
            row[prop],
            {
              op,
              value,
              path
            }
          )) {
          isFound.push(true);
        } else {
          isFound.push(false);
        }
      }

      return isFound.indexOf(false) === -1;
    });
  }

  deleteByObjectId({ id }) {
    this.rows = this.rows.filter(r => {
      return r.id !== id;
    });

    this.filteredRows = this.filteredRows.filter(r => {
      return r.id !== id;
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    for (const subsc of this.subsc) {
      subsc.unsubscribe();
    }
  }
}
