// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { env } from "ng-viv-ux";

export const environment: env = {
  production: true,
  sdk: import('ng-vex-sdk'),
  dark_logo: 'Logo-techmartsite4.png',
  light_logo: 'Logo-techmartsite4_light.png',
  title: 'Techmart',
  version: require('../../package.json').version,
  apiAccessPoint: {
    protocol: 'https',
    hostname: 'vex.demo.viv-design.com',
    path: 'api'
  },
  // This shoud be removed
  showLabelKeyIfNotFound: true,
  // tslint:disable-next-line: max-line-length
  apiToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyNSIsImp0aSI6IjljZDIxZTNhODY0YjJiMGI0MDFmODFmZWU5YWY1YmFmNmI0YTE2NjUwZDJlMjEzZmMxZGQ4Mzg2OWQ1M2NlOWNmNmI2MjQ1YjcwNTM1Nzc0IiwiaWF0IjoxNjc4Njk4MDEzLjI1NTk2OSwibmJmIjoxNjc4Njk4MDEzLjI1NTk3NCwiZXhwIjoxNzEwMjM0MDEzLjI0ODA0Mywic3ViIjoiIiwic2NvcGVzIjpbXX0.Ku5tbLZfXT8oezx4Sudh7Mz7j0johiqUuD8reW2flO6QmMI2JCb98haf0DTUAU4Nb5CRlbBzJGu21UrxThifVOqfggN4zSmI_7O34Z7I2_xlliNHXM3DPBTn0o70vlj_yrRGvnu7R4NnlUBmgfUXFhu_z8V2cgTYhg0YFnh70i65CC7am_niktYoPV4X58S3MSAFFaNKooXAnq2Iw12l5BInK89TdnALJD6-VJT1XAsB4GratyQx6Lr20hoMMZm72fbK083gPKGumVUdaVPKIRPlYSdMIdUWiTPx49OnYW1tfUteovXeHkRCcQ3bbMP1eZRzWzPnDxzTpGW2T1j3f8W7CgDl_Z_zzAZAev7waUm-naM6WGU3aj1fzyW5Y8mvLxcPwl9ahN2xNU5nEl1HRPLmw9MC1IeBwWX-D5Yv99n_eHyse1glUnHY1rXaKAC1ZtHok6wOc3PVHvAUyMX1vmstN4Z4zGhl20YlDvXQbM0zD6mjqPdECxRXBR4aKPQWiuNKrSmmQ9TtcqbVHea_kb4kN5oRCLmRwa4qes9v6TI7NE0k4XK2YuEmKD6eE80hs0x5a37oOMHRXAY0ItpgOJmn8pbIjLyTBDH5xTxmNM8qNZjc6ZQBquvX6UwtSBXdtpmaVUL4h4KYOhc4hiqA_82mFLRLBrleuWgJszu-SlA',
  mapApiToken: 'AIzaSyCfecV8GfLbgx6Ciw3uDhcPV3S-UtarrvQ',
  recaptchaApiToken: '6LcEjiEgAAAAADk_cANagYtJqPG7Cx3ZkrwilUfO',
  noLoaderRequests: [
    {
      url: '/api',
      body: {
        method: 'app.locale.getLocales'
      }
    },
    {
      url: '/api',
      body: {
        method: 'access.user.checkSession'
      }
    },
    {
      url: '/api',
      body: {
        method: 'app.label.getPublished'
      }
    },
    {
      url: '/api',
      body: {
        method: 'menu.menu.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'banner.banner.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.list'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.search'
      }
    },
    // {
    //   url: '/api?page=1',
    //   body: {
    //     method: 'product.product.search'
    //   }
    // },
    {
      url: '/api',
      body: {
        method: 'order.cart.loadCart'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'product.product.lastViewedProducts'
      }
    },
    {
      url: '/api',
      body: {
        method: 'category.category.view'
      }
    },
    {
      url: '/api',
      body: {
        method: 'filter.filter.render'
      }
    },
    {
      url: './assets/routing-mock.json?v=1.0.0'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
