import { Injectable } from '@angular/core';
import { ImagesService } from '../images/images.service';
import {
  ApiCallService,
  Mutation,
  Translations,
  Batch,
  SearchParams
} from '../api-call.service';

interface ListProductParams {
  pricing?: boolean;
  with?: string[];
  paginate?: boolean;
  mutations?: Mutation[];
  batch?: Batch;
}

interface ProductParams {
  id: number;
  batch?: Batch;
}

interface ViewProductParams extends ProductParams {
  with: string[];
}

interface CreateProductParams {
  code?: string;
  status: boolean;
  order?: number;
  translations: Translations;
  prices: { [key: string]: number };
  batch?: Batch;
}

interface UpdateProductParams extends ProductParams, CreateProductParams { }

interface NomenclaturesParams {
  productId: number;
  categories: number[];
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService extends ImagesService {
  protected module = 'product';
  protected controller = 'product';

  constructor(api: ApiCallService) {
    super(api);
  }

  public search(params?: SearchParams, page?) {
    let batch;
    if (typeof params !== 'undefined') {

      if (typeof params.filters !== 'undefined') {
        for (const filter in params.filters) {
          if (!params.filters[filter] && [0, false].indexOf(params.filters[filter]) < 0) {
            delete params.filters[filter];
          }
        }
      }
      if (typeof params.batch !== 'undefined') {
        batch = params.batch;
        delete params.batch;
      }
    }
    return this.api.call(
      this.module + '.' + this.controller + '.search',
      params,
      page
    );
  }

  public list(params?: ListProductParams, page?) {
    return this.api.call(
      this.module + '.' + this.controller + '.list',
      params,
      page
    );
  }

  public view(params: ViewProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.view',
      params,
      undefined
    );
  }

  public create(params: CreateProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.store',
      params,
      undefined
    );
  }

  public update(params: UpdateProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.update',
      params,
      undefined
    );
  }

  public delete(params: ProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.delete',
      params,
      undefined
    );
  }

  public nomenclatures(params?: NomenclaturesParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.nomenclatures',
      params,
      undefined
    );
  }

  public lastViewed(params: ViewProductParams) {
    return this.api.call(
      this.module + '.' + this.controller + '.lastViewedProducts',
      params,
      undefined
    );
  }
}
