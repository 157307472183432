import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../backbone/api.service';
import { LanguageService } from '../backbone/language.service';
import { ISlotComponent } from './slot/slot-component';
import { take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ScrollService } from '../backbone/scroll.service';

@Component({
    selector: 'app-base',
    template: '',
    styleUrls: []
})
export class BaseComponent implements ISlotComponent, OnInit {
    @Input() data: any;
    protected destroyed = new Subject<void>();

    constructor(
        public language: LanguageService,
        public scroll: ScrollService,
        protected api: ApiService
    ) { }

    ngOnInit(): void {
        if (typeof this.data.scrollTop === 'function') {
            this.scroll.subscribe(this.destroyed, (e: Event) => {
                this.scrollTop(document.documentElement.scrollTop);
            });
        }
    }

    getLabel(label, data) {
        if (label.data) {
            label.data = data;
        }
        return this.language.getLabel(label);
    }

    scrollTop(value) {
        if (!this.data.scrollTopParams) {
            this.data.scrollTopParams = {};
        }
        this.data.scrollTopParams.event = 'scrollTop';
        this.data.scrollTopParams.value = value;

        const result = this.data.scrollTop(this.data.scrollTopParams);
        if (result instanceof Observable) {
            result.subscribe();
        }
    }

    public load() {
        this.api.callServiceMethod(this.data.dataSource)
            .pipe(take(1))
            .subscribe((response) => {
                this.data.dataObject = response.result.data;
            });
    }
}