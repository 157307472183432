import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../backbone/api.service';
import { take, takeUntil } from 'rxjs/operators';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute } from '@angular/router';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { LanguageService } from '../../backbone/language.service';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() public data: any;

  private destroyed: Subject<void> = new Subject();
  public breadCrumbs: Breadcrumb[] = [];
  public urlParams: any;
  constructor(private api: ApiService,
              private comm: CommunicationService,
              private route: ActivatedRoute,
              private arrayPath: GetArrayPathService,
              private language: LanguageService) { }

  ngOnInit(): void {
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    if (this.data.dataSource) {
      this.urlParams = JSON.parse(JSON.stringify(this.data.dataSource.params));

      this.route.params.pipe(
        takeUntil(this.destroyed)
      ).subscribe(params => {
        let replaced: string = JSON.stringify(this.urlParams);

        for (const key of Object.keys(params)) {
          const search: string = ':' + key;
          replaced = replaced.replace(new RegExp(search, 'g'), params[key]);
        }

        this.load(replaced);
      });
    } else {
      this.addInlineBreadcrumbs(this.data.startingBreadCrumbs, null);
    }
  }

  public load(replacedParams: string): void {
    const dataSource: any = {...this.data.dataSource};
    dataSource.params = JSON.parse(replacedParams);

    this.api.callServiceMethod(dataSource)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.breadCrumbs = [];

        const items: any[] = this.arrayPath.get(response.result.data, this.data.itemsPath);

        if (this.data.startingBreadCrumbs) {
          const startingItem = this.arrayPath.get(response.result.data, this.data.startingItemsPath);

          this.addInlineBreadcrumbs(this.data.startingBreadCrumbs, startingItem);
        }

        this.addBreadcrumbs(items);

        if (this.data.endingBreadCrumbs) {
          const endingItem = this.arrayPath.get(response.result.data, this.data.endingItemsPath);

          this.addInlineBreadcrumbs(this.data.endingBreadCrumbs, endingItem);
        }
      });
  }

  public loadFromExternal(params: any) {
    const items: any = this.arrayPath.get(params, this.data.itemsPath);

    if (!params.doNotResetExistingBreadcrumbs) {
      this.breadCrumbs = [];
    }

    if (this.data.startingBreadCrumbs) {
      this.addInlineBreadcrumbs(this.data.startingBreadCrumbs, null);
    }

    this.addBreadcrumbs(items);
  }

  public loadInlineFromExternal(params: any) {
    if (!params.doNotResetExistingBreadcrumbs) {
      this.breadCrumbs = [];
    }

    this.addInlineBreadcrumbs(params.breadCrumbs, params.item);
  }

  private addBreadcrumbs(items: any[]) {
    (items).forEach(item => {
      this.breadCrumbs.push({
        label: this.arrayPath.get(item, this.data.labelPath),
        url: this.data.baseBreadCrumbPath + this.arrayPath.get(item, this.data.slugPath)
      });
    });
  }

  private addInlineBreadcrumbs(inlineBreadCrumbs: any[], item: any) {
    inlineBreadCrumbs.forEach(bc => {
      const label: string = bc.hasOwnProperty('labelPath') ?
        this.arrayPath.get(item, bc.labelPath) :
        this.language.getLabel(bc.label);

      const url: string = bc.hasOwnProperty('urlPath') ?
        bc.urlPath.slice(0, 1) + this.arrayPath.get(item, bc.urlPath.slice(1)) :
        bc.url;

      this.breadCrumbs.push({
        label,
        url
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
