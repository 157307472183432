import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { BaseComponent } from '../base.component';
import { ScrollService } from '../../backbone/scroll.service';
import { LanguageService } from '../../backbone/language.service';
import { ApiService } from '../../backbone/api.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-remote-slot',
  templateUrl: './remote-slot.component.html',
  styleUrls: ['./remote-slot.component.scss']
})
export class RemoteSlotComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() public data: any;
  protected destroyed = new Subject<void>();

  private urlParams;

  public items = [];

  constructor(
    protected api: ApiService,
    public language: LanguageService,
    public scroll: ScrollService,
    private route: ActivatedRoute
  ) {
    super(language, scroll, api);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.data.reloadsWithUrl) {
      // Trigger load if query params or url param has been changed
      combineLatest([
        this.route.queryParams.pipe(startWith(null)),
        this.route.params.pipe(startWith(null))
      ]).pipe(
        takeUntil(this.destroyed),
        debounceTime(0)
        ).subscribe((params) => {
          const urlParams = JSON.parse(JSON.stringify(params));
          if (
            this.urlParams
            && JSON.stringify(this.urlParams) !== JSON.stringify(urlParams)
          ) {
            super.load();
          }
          this.urlParams = urlParams;
      });
    }
    super.load();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
