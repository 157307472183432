import { Injectable } from '@angular/core';
import { fromEvent, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private scroll$;
  constructor() {
    this.scroll$ = fromEvent(window, 'scroll');
  }

  public subscribe(until$, callback) {
    this.scroll$.pipe(takeUntil(until$)).subscribe(callback);
  }
}
