import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { LinkPipe } from '../../../../backbone/pipes/link.pipe';

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  providers: [LinkPipe]
})
export class MenuPanelComponent implements OnInit {
  @Input() public data: any = {};
  @Input() public parentItem: any;
  @Input() public menuItems: object;
  @ViewChild('menu', { static: true }) menu: MatMenu;

  constructor(private link: LinkPipe) { }

  ngOnInit() {
    if (typeof this.data.xPosition === 'undefined') {
      this.data.xPosition = 'after';
    }
    if (typeof this.data.class !== 'undefined') {
      this.menu.overlayPanelClass = this.data.class;
    }
  }

  getRouterLink(item) {
    if (item.url) {
      return item.url;
    } else if (typeof this.data.link !== 'undefined') {
      return this.link.transform(item, this.data.link);
    }
  }
}
