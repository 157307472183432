import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {Observable, take} from 'rxjs';
import { LanguageService } from '../../../backbone/language.service';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { ApiService } from '../../../backbone/api.service';
import { GetArrayPathPipe } from '../../../backbone/pipes/get-array-path.pipe';
import { convertCompilerOptionsFromJson } from 'typescript';

@Component({
  selector: 'app-slider-range',
  templateUrl: './slider-range.component.html',
  styleUrls: ['./slider-range.component.scss'],
  providers: [GetArrayPathPipe]
})

export class SliderRangeComponent implements OnInit {
  @Input() public data: any = {};
  @Input() public parentForm: UntypedFormGroup;

  public options: Options = {};


  constructor(
    public language: LanguageService,
    private api: ApiService,
    private getArrayPath: GetArrayPathPipe
    ) { }

  ngOnInit(): void {
    if (typeof this.data.dataSource !== 'undefined') {
      this.api.callServiceMethod(this.data.dataSource)
      .pipe(take(1)).subscribe((response) => {
        if (this.data.dataSource.path !== 'undefined'){
          const data = this.getArrayPath.transform(
            response.result.data,
            this.data.dataSource.path
          );
          this.setOptions(data);
        } else {
          const data = response.result.data;
          this.setOptions(data);
        }
      });
    } else if (typeof this.data.dataSource === 'undefined') {
      this.setOptions(this.data);
    }
  }
  setOptions(data){
    this.options = {
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return data.options.label + value;
          case LabelType.High:
            return data.options.label + value;
          default:
            return data.options.label + value;
        }
      }
    };

    this.options.floor = data.options.floor;
    this.options.ceil = data.options.ceil;
    this.options.showTicks = data.options.showTicks;
    this.options.showTicksValues = data.options.showTicksValues;
    this.options.step = data.options.step;
  }

  change(changeContext: ChangeContext): void {
    console.log('change');
    // TODO value set doesn't work
    if (typeof(this.parentForm) !== 'undefined') {
      console.log(this.parentForm);
      this.parentForm.controls[this.data.name].setValue(
        {
          min: changeContext.value,
          max: changeContext.highValue,
        }
      );
    }

    if (typeof this.data.change === 'function') {
      if (!this.data.changeParams) {
        this.data.changeParams = {};
      }

      this.data.changeParams.value = {
        min: changeContext.value,
        max: changeContext.highValue,
      };
      this.data.changeParams.event = 'change';

      const result = this.data.change(this.data.changeParams);
      if (result instanceof Observable) {
        result.subscribe();
      }
    }
  }
}
