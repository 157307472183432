import { Injectable } from '@angular/core';
import { ApiCallService } from '../api-call.service';

interface BaseParams {
  recipientId: number;
}

interface ListMessagesParams extends BaseParams {
  paginate?: boolean;
}

interface Sender {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
}

interface SendMessageParams extends BaseParams {
  sender: Sender;
  title: string;
  message: string;
}

interface MessageParams extends BaseParams {
  notificationId: number;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private api: ApiCallService) { }

  public list(params?: ListMessagesParams, page?) {
    return this.api.call('messaging.message.list', params, page);
  }
  public send(params: SendMessageParams) {
    return this.api.call('messaging.message.send', params);
  }
  public read(params: MessageParams) {
    return this.api.call('messaging.message.read', params);
  }
  public delete(params: MessageParams) {
    return this.api.call('messaging.message.delete', params);
  }
}
