import {
  Component,
  OnInit,
  Input,
  ViewChild,
  NgZone,
  OnDestroy,
  ElementRef,
  Injector
} from '@angular/core';
import { ISlotComponent } from '../slot/slot-component';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../../backbone/api.service';
import { catchError, take, takeUntil } from 'rxjs/operators';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormArray,
  ValidatorFn
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GetArrayPathPipe } from '../../backbone/pipes/get-array-path.pipe';
import { ToastComponent } from '../toast/toast.component';
import { StateService } from '../../backbone/state.service';
import { PermissionsService } from '../../backbone/permissions.service';
import { LanguageService } from '../../backbone/language.service';
import { JoinPipe } from '../../backbone/pipes/join.pipe';
import { EvalService } from '../../backbone/eval.service';
import { DatePipe, Location } from '@angular/common';
import { SessionService } from '../../backbone/session.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { CloneAbstractControlService } from '../../backbone/clone-abstract-control.service';
import { CustomValidators } from '../../backbone/validators';
import { Condition } from '../../backbone/interfaces/condition.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import * as executables from '../../backbone/executables';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { GetControlByPathService } from '../../backbone/get-control-by-path.service';
import { CustomFormControl } from '../../backbone/interfaces/custom-form-control.class';
// import { ReCaptchaV3Service } from 'ng-recaptcha';


interface Validation {
  condition?: Condition;
  rules: Array<string | { [key: string]: string; }>;
  messages: { [key: string]: string; };
}

interface DataSource {
  service: string;
  method: string;
  params?: { [key: string]: any; };
  valuePath: string | string[];
  labelPath?: string | string[];
  multiLabelPath?: Array<string | string[]>;
  multiLabelPathGlue?: string;
  multiLabelPathPrefix?: string;
}

interface BaseFormField {
  type: string;
  inputType?: string;
  value?: any;
  disabled?: boolean | "invalid";
  valuePath?: string | string[];
  formArray?: string;
  formGroup?: string;
  multiple?: boolean;
  dataSource?: DataSource;
  options?: any[];
  optionsPath?: string | string[];
  optionTextPath?: string | string[];
  optionValuePath?: string | string[];
  validation?: Validation;
  translatable?: string | boolean;
  selected?: any;
  requires?: string[];
  def?: any;
  control?: any;
  events?: any;
}
interface ButtonData {
  disabled: boolean;
  click: () => void;
}
interface FormField extends BaseFormField {
  name: string;
  label: string;
  placeholder: string;
  buttonData: ButtonData;
}

interface ExtractedFormField extends BaseFormField {
  id?: string;
  name: string | string[];
  label: string | string[];
  buttonData: ButtonData;
  placeholder: string | string[];
  extractPath: string | string[];
}

interface GridColumn {
  class: string;
  fields: FormField[];
}

interface GridRow {
  height?: string[];
  formArray?: string;
  groupBy?: string[];
  cols: GridColumn[];
  expandable?: boolean;
  draggable?: boolean;
  dragPlaceholderHeight?: number;
}


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [GetArrayPathPipe, JoinPipe],
  animations: [
    trigger('toggle', [
      state('open', style({
        height: '*'
      })),
      state('closed', style({
        height: '{{collapsedHeight}}px'
      }), { params: { collapsedHeight: 185 } }),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ])
    ])
  ]
})

export class FormComponent implements OnInit, OnDestroy, ISlotComponent {
  @Input() public data: any;
  @Input() public parentComponent: any;
  @ViewChild('form', { static: true }) form: ElementRef;

  public formData = new UntypedFormGroup({});
  public id: number;

  public languages: string[];
  public hasTranslatable: boolean;
  public translatableFields: object;
  public extractedFields: object = {};
  public fieldGroups: { [key: string]: UntypedFormGroup; } = {};
  public formArrays: { [key: string]: UntypedFormArray; } = {};
  public triggerActionChange: number;
  public rows: { [key: string]: any; } = {};

  private subsc: Subscription[] = [];
  private destroyed = new Subject<void>();
  private initialFieldValues = {
    checkbox: false
  };

  public dragPlaceholderHeight = 60;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private getArrayPath: GetArrayPathPipe,
    private join: JoinPipe,
    private evaluator: EvalService,
    private alert: MatSnackBar,
    private dialog: MatDialog,
    private zone: NgZone,
    private state: StateService,
    private session: SessionService,
    private cloner: CloneAbstractControlService,
    public permissionSevice: PermissionsService,
    public language: LanguageService,
    public location: Location,
    private injector: Injector,
    private getArrayPathService: GetArrayPathService,
    private comm: CommunicationService,
    private getControlByPath: GetControlByPathService
    // ,
    // private recaptcha: ReCaptchaV3Service
  ) { }

  private getFormArrayItemName(formArray) {
    return 'formArray'
      + formArray.charAt(0).toUpperCase()
      + formArray.slice(1)
      + 'Item';
  }

  private getInitialValue(field) {
    let initialValue = '';
    if (typeof this.initialFieldValues[field.type] !== 'undefined') {
      initialValue = this.initialFieldValues[field.type];
    }

    return initialValue;
  }

  ngOnInit() {
    // execute before init event handler if form is in action
    if (typeof this.data.beforeInit === 'function') {
      if (!this.data.beforeInitParams) {
        this.data.beforeInitParams = {};
        this.data.beforeInitParams.event = 'beforeInit';
        if (this.data.dataObject) {
          this.data.beforeInitParams.dataObject = this.data.dataObject;
        }
      }
      const result = this.data.beforeInit(this.data.beforeInitParams);
      if (result instanceof Observable) {
        result.pipe(take(1)).subscribe();
      }
    }
    this.rows = JSON.parse(JSON.stringify(this.data.rows));

    const languages = this.state.get('languages.all');
    this.languages = Object.keys(languages);
    this.translatableFields = {};

    this.init();

    // subscribe to communication channel if such is provided
    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => this.comm.processMessage(message, this));
    }

    // execute last
    setTimeout(() => {
      if (this.data.loadOnInit !== false) {
        this.load();
      }

      // execute init event handler if form is in action
      if (typeof this.data.init === 'function') {
        if (!this.data.initParams) {
          this.data.initParams = {};
          this.data.initParams.event = 'init';
          if (this.data.dataObject) {
            this.data.initParams.dataObject = this.data.dataObject;
          }
        }
        const result = this.data.init(this.data.initParams);
        if (result instanceof Observable) {
          result.pipe(take(1)).subscribe();
        }
      }

      // Listen for value changes and execute valueChanges event on change
      if (typeof this.data.valueChanges === 'function') {
        this.formData.valueChanges
        .pipe(takeUntil(this.destroyed))
        .subscribe(value => {
          if (!this.data.valueChangesParams) {
            this.data.valueChangesParams = {};
            this.data.valueChangesParams.event = 'valueChanges';
            if (this.data.dataObject) {
              this.data.valueChangesParams.dataObject = value;
            }
          }
          const result = this.data.valueChanges(this.data.valueChangesParams);
          if (result instanceof Observable) {
            result.pipe(take(1)).subscribe();
          }
        });
      }

    });
    this.registerInParent();
  }
  public init() {
    this.formData.setValidators(this.validateControl(this.data));
    // TODO figure out how not to duplicate if already exists
    this.formData.statusChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(newStatus => {
        if (newStatus === 'VALID' && typeof this.data.validStatus === 'function') {
          if (!this.data.validStatusParams) {
            this.data.validStatusParams = {};

            this.data.validStatusParams.event = 'validStatus';
            if (this.data.dataObject) {
              this.data.validStatusParams.dataObject = this.data.dataObject;
            }
          }

          // this.data.validStatusParams.target = event?.target;
          this.data.validStatusParams.formValue = this.formData.value;

          const result = this.data.validStatus(this.data.validStatusParams);

          if (result instanceof Observable) {
            result.pipe(take(1)).subscribe(() => { });
          }
        }

        if (newStatus === 'INVALID' && typeof this.data.invalidStatus === 'function') {
          if (!this.data.invalidStatusParams) {
            this.data.invalidStatusParams = {};

            this.data.invalidStatusParams.event = 'invalidStatus';
            if (this.data.dataObject) {
              this.data.invalidStatusParams.dataObject = this.data.dataObject;
            }
          }

          // this.data.validStatusParams.target = event?.target;
          this.data.invalidStatusParams.formValue = this.formData.value;

          const result = this.data.invalidStatus(this.data.invalidStatusParams);

          if (result instanceof Observable) {
            result.pipe(take(1)).subscribe(() => { });
          }
        }
      });
    this.rows.forEach((row: GridRow) => {
      if (row.formArray) {
        if (typeof row.dragPlaceholderHeight !== 'undefined') {
          this.dragPlaceholderHeight = row.dragPlaceholderHeight;
        }
        this.formData.addControl(
          row.formArray,
          new UntypedFormArray([])
        );
      }
      row.cols.forEach((col) => {
        if (typeof col.fields !== 'undefined') {
          col.fields.forEach((field) => {
            if (typeof field.disabled !== 'undefined' && field.disabled === 'invalid') {
              field.disabled = this.formData.invalid;
              if (field.type === 'submit') {
                field.buttonData.disabled = field.disabled;
              }
              this.formData.statusChanges
                .pipe(takeUntil(this.destroyed))
                .subscribe(newStatus => {
                  // TODO works inconsistently
                  // figure out a way to work with the field control
                  // rather than the field definition
                  field.disabled = newStatus === 'INVALID';
                  if (field.type === 'submit') {
                    field.buttonData.disabled = field.disabled;
                  }
                });
            }
            if (!this.permissionSevice.checkPermissions(field)) {
              return;
            }

            if (row.formArray) {
              field.formArray = row.formArray;
              field.formGroup = this.getFormArrayItemName(row.formArray);
            }

            if (typeof field.translatable !== 'undefined') {
              // TODO: Backword compatibility, remove when changed to boolean true
              // in routing configurations of all projects (bgair, vedra, kfn)
              if (field.translatable === 'true') {
                field.translatable = true;
              }
              // default header tabs position is above
              if (field.translatable === true) {
                field.translatable = 'above';
              }
            }
            if (field.translatable) {
              this.translatableFields[field.name] = {};
              this.languages.forEach((lang) => {
                const fieldName = 'translations-' + lang + '-' + field.name;
                const transField = Object.assign({}, field);
                transField.name = fieldName;
                this.translatableFields[field.name][lang] = transField;
                this.hasTranslatable = true;
                if (transField.formGroup) {
                  if (typeof this.fieldGroups[transField.formGroup] === 'undefined') {
                    // if formGroup attribute is present initiate form group
                    // with the formGroup attribute value as name
                    this.fieldGroups[transField.formGroup] = new UntypedFormGroup({});
                    this.formData.addControl(
                      transField.formGroup,
                      this.fieldGroups[transField.formGroup]
                    );
                  }
                  // if field is part of a group add it to the Form Group control
                  // and refresh the control
                  const control = new CustomFormControl(
                    {
                      value: this.getInitialValue(transField),
                      disabled: (transField.disabled)
                    },
                    this.validateControl(transField)
                  );
                  control.def = transField;
                  this.fieldGroups[transField.formGroup].addControl(fieldName, control);

                  this.formData.setControl(
                    transField.formGroup,
                    this.fieldGroups[transField.formGroup]
                  );

                } else {
                  this.formData.addControl(
                    fieldName,
                    new UntypedFormControl(
                      {
                        value: this.getInitialValue(transField),
                        disabled: transField.disabled
                      },
                      this.validateControl(transField)
                    )
                  );
                }
              });
            } else {
              const testField = field as ExtractedFormField;
              if (!testField.extractPath) {
                if (field.formGroup) {
                  if (typeof this.fieldGroups[field.formGroup] === 'undefined') {
                    // if formGroup attribute is present initiate form group
                    // with the formGroup attribute value as name
                    this.fieldGroups[field.formGroup] = new UntypedFormGroup({});
                    this.formData.addControl(
                      field.formGroup,
                      this.fieldGroups[field.formGroup]
                    );
                  }
                  // if field is part of a group add it to the Form Group control
                  // and refresh the control
                  const control = new CustomFormControl(
                    {
                      value: this.getInitialValue(field),
                      disabled: (field.disabled)
                    },
                    this.validateControl(field)
                  );
                  control.def = field;
                  this.fieldGroups[field.formGroup].addControl(field.name, control);

                  this.formData.setControl(
                    field.formGroup,
                    this.fieldGroups[field.formGroup]
                  );

                } else {
                  if (field.type === 'dateRange') {
                    this.formData.addControl(
                      field.name + '_from',
                      new UntypedFormControl(
                        {
                          value: this.getInitialValue(field),
                          disabled: (field.disabled)
                        },
                        this.validateControl(field)
                      )
                    );
                    this.formData.addControl(
                      field.name + '_to',
                      new UntypedFormControl(
                        {
                          value: this.getInitialValue(field),
                          disabled: (field.disabled)
                        },
                        this.validateControl(field)
                      )
                    );
                  } else {
                    const control = new CustomFormControl(
                      {
                        value: this.getInitialValue(field),
                        disabled: (field.disabled)
                      },
                      this.validateControl(field)
                    );
                    control.def = field;
                    this.formData.addControl(
                      field.name,
                      control
                    );
                  }
                }
                switch (field.name) {
                  case 'cancel':
                    field.buttonData.click = function () {
                      this.location.back();
                    }.bind(this);
                    break;
                }
              }
            }
            if (
              field.dataSource
              && ['multi-autocomplete', 'autocomplete', 'select'].indexOf(field.type) < 0
            ) {
              if (typeof field.dataSource.params === 'undefined') {
                field.dataSource.params = {};
              }
              const dataService = this.api.getService(field.dataSource.service);
              dataService[field.dataSource.method](field.dataSource.params)
                .pipe(take(1))
                .subscribe((response: any) => {
                  switch (field.type) {
                    default:
                      field.value = this.getArrayPath.transform(
                        response.result.data,
                        field.dataSource.valuePath
                      );
                      break;
                  }
                });
            }

            if (
              typeof this.data.dataSource === 'undefined'
              && typeof this.data.dataObject === 'undefined'
              && typeof field.dataSource === 'undefined'
              && typeof field.valuePath !== 'undefined'
            ) {
              const storage = this[field.valuePath[0]];
              const path = [...field.valuePath];
              path.shift();
              const value = storage.get(path.join('.'));
              if (field.formGroup) {
                this.formData.get(field.formGroup).get(field.name).setValue(value);
              } else {
                this.formData.get(field.name).setValue(value);
              }
            }
            if (field.value || field.value === 0) {
              // If the field has default value that comes from the url or directly
              // from the config json
              let value: any = field.value;
              this.route.params
                .pipe(takeUntil(this.destroyed))
                .subscribe(urlParams => {
                  let replaced = '';
                  for (const key of Object.keys(urlParams)) {
                    if (typeof value === 'string') {
                      const search = ':' + key;
                      replaced = value.replace(new RegExp(search, 'g'), urlParams[key]);
                      if (!replaced.startsWith(':')) {
                        if (
                          (field.type === 'select'
                            && field.multiple)
                          || (field.type === 'action'
                            && field.control.component === 'SelectComponent'
                            && field.control.data.multiple)
                        ) {
                          value = replaced.split(',');
                        } else {
                          if (!isNaN(+replaced)) {
                            value = +replaced;
                          } else {
                            value = replaced;
                          }
                        }
                      }
                    }
                  }
                  if (typeof value === 'string' && value.startsWith(':')) {
                    value = '';
                  }
                });
              if (field.formGroup) {
                this.formData.get(field.formGroup).get(field.name).setValue(value);
              } else {
                this.formData.get(field.name).setValue(value);
              }
            }
          });
        }
      });
      if (row.formArray) {
        this.formArrays[row.formArray] = this.formData.get(row.formArray) as UntypedFormArray;
        // tslint:disable-next-line: no-string-literal
        this.formArrays[row.formArray]['name'] = row.formArray;
        const formArrayItemName = this.getFormArrayItemName(row.formArray);
        this.formArrays[row.formArray].push(this.fieldGroups[formArrayItemName]);
        this.formData.removeControl(formArrayItemName);
      }
    });
  }

  public load(dataSourceParamsToAdd?: { [key: string]: any; }) {
    this.route.paramMap
      .pipe(take(1))
      .subscribe(params => {
        // TODO should be removed and hidden input should be used for id
        this.id = +params.get('id');
        if (this.id > 0) {
          this.formData.addControl('id', new UntypedFormControl(''));
          this.formData.get('id').setValue(this.id);
        }
        // END

        if (typeof this.data.dataSource !== 'undefined') {
          const dataService = this.api.getService(this.data.dataSource.service);
          // parse data source params and replace any url params that are mapped
          let dataSourceParams;
          if (typeof this.data.dataSource.params !== 'undefined') {
            let paramStr = JSON.stringify(this.data.dataSource.params);
            if (typeof this.data.dataSource.params === 'object') {
              const pattern = /\"(:[\w-]+?)\"/g;
              let placeholder;
              // tslint:disable-next-line: no-conditional-assignment
              while ((placeholder = pattern.exec(paramStr)) !== null) {
                const param = placeholder[1].replace(':', '');
                if (param) {
                  const search = placeholder[1];
                  paramStr = paramStr.replace(search, params.get(param));
                }
              }
              // if values from internal storage should be passed
              const internalStorageValues = /{([\w-]+)\.([\w.-]+)}/gm;
              let internalStorageMatch: any;
              // tslint:disable-next-line: no-conditional-assignment
              while ((internalStorageMatch = internalStorageValues.exec(paramStr)) !== null) {
                const statePath = [];
                let paramValuePath;
                if (internalStorageMatch.index === internalStorageValues.lastIndex) {
                  internalStorageValues.lastIndex++;
                }
                // Always skip first itteration as it will return the whole object param
                internalStorageMatch.forEach((match: string, groupIndex: number) => {
                  if (groupIndex !== 0) {
                    statePath.push(match);
                  } else {
                    paramValuePath = match;
                  }
                });
                if (statePath.length) {
                  const pathValue = this.getArrayPathService.get(undefined, statePath);
                  paramStr = paramStr.replace(new RegExp(paramValuePath, 'g'), pathValue);
                }
              }
            }
            dataSourceParams = JSON.parse(paramStr);
          } else {
            dataSourceParams = {};
          }

          if (typeof dataSourceParamsToAdd !== 'undefined') {
            dataSourceParams = Object.assign(dataSourceParams, dataSourceParamsToAdd);
          }

          dataService[this.data.dataSource.method](dataSourceParams)
            .pipe(take(1))
            .subscribe((response: any) => {
              this.data.dataObject = response.result.data;
              this.rows.forEach((row: GridRow) => {
                if (row.formArray) {
                  if (typeof response.result.data[row.formArray] !== 'undefined') {
                    // If the row is form array find all matched elements in the
                    // response and set the values
                    const resultArray = response.result.data[row.formArray];
                    const formArray = this.formArrays[row.formArray];
                    let lastGroupUid = 0;
                    // tslint:disable-next-line: forin
                    for (const index in resultArray) {
                      const clonedControl = this.cloner
                        .clone(formArray.at(0)) as UntypedFormArray;
                      // tslint:disable-next-line: no-string-literal
                      clonedControl['dataObject'] = resultArray[index];
                      if (row.groupBy) {
                        // if formArray rows need to be group by a value to be removed
                        // together set the group id
                        // tslint:disable-next-line: no-string-literal
                        clonedControl['groupUid'] = this.getArrayPath.transform(
                          resultArray[index],
                          row.groupBy
                        );
                        // tslint:disable-next-line: no-string-literal
                        if (lastGroupUid !== clonedControl['groupUid']) {
                          // tslint:disable-next-line: no-string-literal
                          clonedControl['startGroupClass'] = 'groupStart';
                        }
                        // tslint:disable-next-line: no-string-literal
                        lastGroupUid = clonedControl['groupUid'];
                      }
                      formArray.push(clonedControl);
                      // setTimeout(() => {
                      for (const fieldName in clonedControl.controls) {
                        if (fieldName.startsWith('translations')) {
                          const parseFieldName = fieldName.split('-');
                          const locale = parseFieldName[1];
                          const prop = parseFieldName[2];
                          const translation = resultArray[index].translations
                            .find(item => item.locale === locale);
                          if (
                            typeof translation !== 'undefined'
                            && typeof translation[prop] !== 'undefined'
                          ) {
                            clonedControl.controls[fieldName].setValue(translation[prop]);
                          }
                        } else {
                          let currentField;
                          row.cols.forEach((col) => {
                            if (typeof col.fields !== 'undefined') {
                              col.fields.forEach((field) => {
                                if (field.name === fieldName) {
                                  currentField = field;
                                  return;
                                }
                              });
                            }
                          });
                          if (typeof currentField !== 'undefined') {
                            if (
                              currentField.type === 'select'
                              && typeof currentField.optionsPath !== 'undefined'
                            ) {
                              // if field is select and options path is specified
                              // population field options
                              currentField.options = this.getArrayPath.transform(
                                resultArray[index],
                                currentField.optionsPath
                              );
                            }
                            this.setFieldValue(
                              currentField,
                              resultArray[index],
                              clonedControl.controls[fieldName]
                            );
                          }
                        }
                      }
                      // });
                    }
                    formArray.controls.map((item: UntypedFormGroup, i) => {
                      const addControl = item.get('add') as CustomFormControl;
                      if (addControl) {
                        if (
                          typeof addControl.def !== 'undefined'
                          && typeof addControl.def.events !== 'undefined'
                          && typeof addControl.def.events.click !== 'undefined'
                          && typeof addControl.def.events.click.addRows !== 'undefined'
                          && addControl.def.events.click.addRows.prepend === true
                        ) {
                          if (i > 1) {
                            addControl.disable();
                          } else {
                            addControl.enable();
                          }
                        } else {
                          if (i < formArray.length - 1) {
                            addControl.disable();
                          }
                        }
                      }
                    });
                    if (resultArray.length > 0) {
                      formArray.removeAt(0);
                    }
                  }
                } else {
                  row.cols.forEach((col) => {
                    if (typeof col.fields !== 'undefined') {
                      col.fields.forEach((field) => {
                        if (!this.permissionSevice.checkPermissions(field)) {
                          return;
                        }
                        if (typeof field.translatable !== 'undefined'
                          && field.translatable
                          && typeof response.result.data.translations !== 'undefined'
                        ) {
                          response.result.data.translations.forEach((translation) => {
                            const fieldName = 'translations-' + translation.locale + '-' + field.name;
                            this.formData.get(fieldName).setValue(translation[field.name]);
                          });
                        } else {
                          const extField = field as ExtractedFormField;
                          if (extField.extractPath) {
                            // if fields needs to extract data from response to generate constrols
                            // find data in response
                            const extractedFieldsData = this.getArrayPath.transform(
                              response.result.data,
                              extField.extractPath
                            );
                            // for each data item generate id based on extractPath
                            let fieldId = this.join.transform(extField.extractPath);
                            if (fieldId === '') {
                              fieldId = field.name;
                            }
                            extField.id = fieldId;
                            this.extractedFields[fieldId] = [];
                            if (
                              extField.formGroup
                              && typeof this.fieldGroups[field.formGroup] === 'undefined'
                            ) {
                              // if formGroup attribute is present initiate form group
                              // with the formGroup attribute value as name
                              this.fieldGroups[field.formGroup] = new UntypedFormGroup({});
                              this.formData.addControl(
                                extField.formGroup,
                                this.fieldGroups[field.formGroup]
                              );
                            }

                            let i = 1;
                            for (const extractedField of extractedFieldsData) {
                              // clone field config
                              const clonedField = JSON.parse(JSON.stringify(field));
                              if (extractedFieldsData.length === i
                                && typeof clonedField.dataSource !== 'undefined'
                                && typeof clonedField.dataSource.params !== 'undefined'
                                && typeof clonedField.dataSource.params.batch !== 'undefined'
                              ) {
                                clonedField.dataSource.params.batch.stage = 'finish';
                              }
                              // remove extractPath as this field is the result of the extraction
                              delete clonedField.extractPath;
                              // extract name, label and placeholder from response
                              clonedField.name = this.getArrayPath.transform(
                                extractedField,
                                field.name
                              );
                              clonedField.label = this.getArrayPath.transform(
                                extractedField,
                                field.label
                              );
                              clonedField.placeholder = this.getArrayPath.transform(
                                extractedField,
                                field.placeholder
                              );
                              if (typeof clonedField.optionsPath !== 'undefined') {
                                // extract field options of optionsPath is specified
                                const options = this.getArrayPath.transform(
                                  extractedField,
                                  field.optionsPath
                                );
                                clonedField.options = options.map((opt) => {
                                  return {
                                    text: this.getArrayPath.transform(opt, field.optionTextPath),
                                    value: this.getArrayPath.transform(opt, field.optionValuePath)
                                  };
                                });
                              }
                              // add form control based on extracted field
                              if (extField.formGroup) {
                                // if field is part of a group add it to the Form Group control
                                // and refresh the control
                                const formState = {
                                  value: this.getInitialValue(extField),
                                  disabled: extField.disabled
                                };
                                switch (typeof clonedField.disabled) {
                                  case 'object':
                                    formState.disabled = this.evaluator.exec(
                                      extractedField,
                                      clonedField.disabled
                                    );
                                    break;
                                  case 'boolean':
                                    formState.disabled = clonedField.disabled;
                                    break;
                                }
                                const control = new CustomFormControl(
                                  formState,
                                  this.validateControl(clonedField));
                                if (clonedField.draggable) {
                                  control.order = i;
                                }
                                this.fieldGroups[extField.formGroup].addControl(
                                  clonedField.name,
                                  control
                                );
                                this.formData.setControl(
                                  extField.formGroup,
                                  this.fieldGroups[extField.formGroup]
                                );
                              } else {
                                this.formData.addControl(
                                  clonedField.name,
                                  new UntypedFormControl(
                                    {
                                      value: this.getInitialValue(clonedField),
                                      disabled: (clonedField.disabled)
                                    },
                                    this.validateControl(clonedField))
                                );
                              }

                              // store extracted field for template rendering
                              this.extractedFields[fieldId].push(clonedField);

                              this.setFieldValue(clonedField, extractedField);
                              i++;
                            }
                          } else {
                            this.setFieldValue(field, response.result.data);
                          }
                        }
                      });
                    }
                  });
                }
              });
              // execute loaded event handler if form is in action
              if (typeof this.data.loaded === 'function') {
                if (!this.data.loadedParams) {
                  this.data.loadedParams = {};
                  this.data.loadedParams.event = 'loaded';
                  if (this.data.dataObject) {
                    this.data.loadedParams.dataObject = this.data.dataObject;
                  }
                }
                const result = this.data.loaded(this.data.loadedParams);
                if (result instanceof Observable) {
                  result.pipe(take(1)).subscribe();
                }
              }
            });
        } else if (typeof this.data.dataObject !== 'undefined') {
          for (const r in this.rows) {
            if (typeof this.rows[r] !== 'undefined') {
              const row: GridRow = this.rows[r];
              for (const col of row.cols) {
                if (typeof col.fields !== 'undefined') {
                  for (const field of col.fields) {
                    if (!this.permissionSevice.checkPermissions(field)) {
                      return;
                    }
                    if (typeof field.translatable !== 'undefined'
                      && field.translatable
                      && typeof this.data.dataObject.translations !== 'undefined'
                    ) {
                      this.data.dataObject.translations.forEach((translation) => {
                        const fieldName = 'translations-' + translation.locale + '-' + field.name;
                        this.formData.get(fieldName).setValue(translation[field.name]);
                      });
                    } else {
                      this.setFieldValue(field, this.data.dataObject);
                    }
                  }
                }
              }
            }
          }
          // listen for changes and update the dataObject
          this.formData.valueChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(value => {
              for (const r in this.rows) {
                if (typeof this.rows[r] !== 'undefined') {
                  const row: GridRow = this.rows[r];
                  for (const col of row.cols) {
                    {
                      if (typeof col.fields !== 'undefined') {
                        for (const field of col.fields) {
                          if (field.translatable) {
                            for (const lang of this.languages) {
                              const fieldName = 'translations-' + lang + '-' + field.name;
                              if (typeof this.data.dataObject.translations === 'undefined') {
                                this.data.dataObject.translations = [];
                              }
                              let foundTranslation = false;
                              for (const translation of this.data.dataObject.translations) {
                                if (translation.locale === lang) {
                                  translation[field.name] = value[fieldName];
                                  foundTranslation = true;
                                }
                              }
                              if (!foundTranslation) {
                                const translation = {
                                  locale: lang
                                };
                                translation[field.name] = value[fieldName];
                                this.data.dataObject.translations.push(translation);
                              }
                            }
                          } else {
                            if (
                              field.valuePath
                              && typeof value[field.name] === 'object'
                              && value[field.name] !== null
                            ) {
                              this.data.dataObject[field.name] = this.getArrayPath.transform(
                                value[field.name],
                                field.valuePath
                              );
                            } else {
                              this.data.dataObject[field.name] = value[field.name];
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
        }
      });
  }

  // private async addRecaptcha(params) {
  //   if (this.data.recaptcha) {
  //     await this.recaptcha.execute(this.data.recaptcha)
  //       .pipe(take(1))
  //       .toPromise()
  //       .then(token => {
  //         params.rcToken = token;
  //       }, error => {
  //         console.log('recaptcha error:');
  //         console.log(error);
  //       });
  //   }
  // }

  private registerInParent() {
    if (
      typeof this.parentComponent !== 'undefined'
      && typeof this.parentComponent.registerForm === 'function'
    ) {
      this.parentComponent.registerForm(this.formData, this);
    }
  }

  private setFieldValue(field, data, fieldControl?) {
    let value;
    if (field.type === 'action' && typeof data !== 'undefined') {
      field.dataObject = data;
      this.triggerActionChange = Math.random();
    }
    if (field.valuePath) {
      if (field.valueFilter) {
        // if value filter is present
        let values = this.getArrayPath.transform(data, field.valueFilter.path);
        // and the path specified leads to an array
        if (Array.isArray(values)) {
          // filter array values by specified condition
          values = values.filter((item) => {
            return this.evaluator.exec(item, {
              path: field.valueFilter.property,
              op: field.valueFilter.op,
              value: field.valueFilter.value,
              any: false
            });
          });
          // select value from filtered array of values
          value = this.getArrayPath.transform(
            values,
            field.valuePath
          );
        }
      } else {
        if (field.type === 'dateRange') {
          value = {
            from: this.getArrayPath.transform(
              data,
              field.valuePath.from
            ),
            to: this.getArrayPath.transform(
              data,
              field.valuePath.to
            )
          };
        } else {
          value = this.getArrayPath.transform(
            data,
            field.valuePath
          );
        }
      }
    } else {
      if (typeof data === 'undefined' || typeof data[field.name] === 'undefined') {
        return;
      }
      if (field.type === 'dateRange') {
        value = {
          from: data[field.name + '_from'],
          to: data[field.name + '_to']
        };
      } else {
        value = data[field.name];
      }
    }
    // select or select in action
    if (
      ((field.type === 'select'
        && field.multiple === false)
        || (field.type === 'action'
          && field.control.component === 'SelectComponent'
          && field.control.data.multiple === false))
      && Array.isArray(value)
    ) {
      value = value[0];
    }
    // date or time picker
    if (['datePicker', 'timePicker'].indexOf(field.type) >= 0 && value) {
      value = new Date(value);
      if (field.type === 'timePicker') {
        if (value.toString() !== 'Invalid Date') {
          const formattedTime = new DatePipe('en-GB').transform(value, 'HH:mm');
          value = formattedTime;
        }
      }
    }
    if (field.type === 'input' && field.inputType === 'time') {
      const date = new Date(value);
      if (date.toString() !== 'Invalid Date') {
        const formattedTime = new DatePipe('en-GB').transform(date, 'HH:mm');
        value = formattedTime;
      }
    }

    // autocomplete or (multi)autocomplete in action
    if (
      field.type === 'autocomplete'
      || field.type === 'multi-autocomplete'
      || (
        field.type === 'action'
        && ['AutocompleteComponent', 'MultiAutocompleteComponent']
          .indexOf(field.control.component) >= 0
      )
    ) {
      let label = '';
      if (field.multiLabelPath) {
        const multiLabelArray = [];
        if (field.multiLabelPathPrefix) {
          multiLabelArray.push(field.multiLabelPathPrefix);
        }
        for (const path of field.multiLabelPath) {
          const lab = this.getArrayPath.transform(
            data,
            path
          );
          if (lab) {
            multiLabelArray.push(lab);
          } else {
            console.warn('Did not find path: ' + path);
          }
        }
        if (typeof field.multiLabelPathGlue === 'undefined') {
          field.multiLabelPathGlue = ' | ';
        }
        label = multiLabelArray.join(field.multiLabelPathGlue);
      } else {
        label = this.getArrayPath.transform(
          data,
          field.labelPath
        );
      }
      if (Array.isArray(label) && Array.isArray(value)) {
        const valArray = [];
        for (const i in label) {
          if (label[i]) {
            valArray.push({
              text: label[i],
              value: value[i]
            });
          }
        }
        if (valArray.length > 1) {
          value = valArray;
        } else {
          value = valArray[0];
        }
      } else if (label === null && value === null) {
        value = null;
      } else {
        value = {
          text: label,
          value
        };
      }

      // field.value = value;
      // field.options = [option];
    }

    if (field.type === 'dateRange') {
      if (typeof fieldControl === 'undefined') {
        fieldControl = {};
      }
      for (const i of ['from', 'to']) {
        if (typeof fieldControl === 'object' && typeof fieldControl[i] === 'undefined') {
          if (field.formGroup) {
            if (field.formArray) {
              fieldControl[i] = this.formData
                .get(field.formArray)
                .get(field.formGroup)
                .get(field.name + '_' + i);
            } else {
              fieldControl[i] = this.formData
                .get(field.formGroup)
                .get(String(field.name + '_' + i));
            }
          } else {
            fieldControl[i] = this.formData.get(field.name + '_' + i);
          }
        }
        fieldControl[i].setValue(value[i]);
      }
    } else {
      if (typeof fieldControl === 'undefined') {
        if (field.formGroup) {
          if (field.formArray) {
            fieldControl = this.formData
              .get(field.formArray)
              .get(field.formGroup)
              .get(field.name);
          } else {
            fieldControl = this.formData
              .get(field.formGroup)
              .get(String(field.name));
          }
        } else {
          fieldControl = this.formData.get(field.name);
        }
      }
      fieldControl.setValue(value);
    }
  }

  public validateControl(element: FormField): ValidatorFn[] {
    const validatorsList = [];
    if (typeof element.validation !== 'undefined' && element.validation.rules) {
      element.validation.rules.forEach((rule: any) => {
        // required validation for translatable fields should be skipped
        // as it depends on a backend setting
        if (element.translatable && rule === 'required') {
          const requiredLanguages = this.state.get('languages.required');
          requiredLanguages.forEach((language: string) => {
            if (element.name.indexOf('-' + language + '-') > 0) {
              validatorsList.push(Validators.required);
            }
          });
          return;
        }
        switch (typeof rule) {
          case 'string':
            validatorsList.push(Validators[rule]);
            break;
          case 'object':
            const validator = String(Object.keys(rule));
            if (Validators[validator]) {
              validatorsList.push(Validators[validator](rule[validator]));
            } else {
              validatorsList.push(CustomValidators[validator](rule[validator]));
            }
            break;
        }
      });
    }
    return validatorsList;
  }

  public hasErrors(
    fieldName: string,
    fieldGroup: string | UntypedFormGroup = null,
    fieldType: string = null
  ) {
    let field;
    if (fieldName !== null) {
      if (fieldType === 'dateRange') {
        return (
          this.hasErrors(fieldName + '_from', fieldGroup)
          || this.hasErrors(fieldName + '_to', fieldGroup)
        );
      }
      if (fieldGroup && typeof fieldGroup === 'object') {
        field = fieldGroup.get(fieldName);
      } else if (typeof fieldGroup === 'string') {
        field = this.formData.get(fieldGroup).get(String(fieldName));
      } else {
        field = this.formData.get(fieldName);
      }
    } else {
      if (typeof fieldGroup === 'object') {
        field = fieldGroup;
      } else {
        field = this.formData.get(fieldGroup);
      }
    }

    return field.invalid && (field.touched || field.dirty);
  }

  public getErrors(
    fieldName: string,
    fieldGroup: string | UntypedFormGroup = null,
    fieldType: string = null
  ) {
    if (fieldType === 'dateRange') {
      return Object.assign(
        this.getErrors(fieldName + '_from') || {},
        this.getErrors(fieldName + '_to') || {}
      );
    }
    let field;
    if (fieldGroup && typeof fieldGroup === 'object') {
      field = fieldGroup.get(fieldName);
    } else if (typeof fieldGroup === 'string') {
      field = this.formData.get(fieldGroup).get(String(fieldName));
    } else {
      field = this.formData.get(fieldName);
    }
    return field.errors;
  }

  public isFieldDisabled(formGroup, name) {
    if (typeof name === 'undefined' || !name) {
      return false;
    }
    return formGroup.get(name).disabled;
  }

  public queryStrToObj(str: string) {
    const obj = {};
    const params = str.split('&');
    for (const i in params) {
      if (params[i]) {
        const param = params[i].split('=');
        obj[param[0]] = param[1];
      }
    }

    return obj;
  }

  public onSubmit(event?: any) {
    if (typeof this.data.submit === 'undefined' || !this.formData.valid) {
      this.formData.markAllAsTouched();
      return;
    }
    if (this.data.confirmDialog) {
      const submitObject = Object.assign({}, this.formData.getRawValue());
      const alertDialog: MatDialogRef<any> = this.dialog.open(AlertDialogComponent,
        {
          data: {
            title: this.language.getLabel(this.data.confirmDialog.title, submitObject),
            text: this.language.getLabel(this.data.confirmDialog.text, submitObject),
            button: this.language.getLabel(this.data.label)
          }
        }
      );

      alertDialog.afterClosed().pipe(take(1)).subscribe({
        next: (result: string) => {
          if (result === 'confirm') {
            this.doSubmit(event);
          }
        }
      });
    } else {
      this.doSubmit(event);
    }
  }

  private async doSubmit(event?: any) {
    const submitObject = Object.assign({}, this.formData.getRawValue());
    Object.keys(this.formData.getRawValue()).forEach((key) => {
      if (Array.isArray(submitObject[key])) {
        // if there are any form arrays
        for (const row in submitObject[key]) {
          if (typeof submitObject[key][row] === 'object') {
            let emptyProps = 0;
            // count how many properties are empty
            for (const prop in submitObject[key][row]) {
              if (typeof submitObject[key][row][prop] !== 'undefined') {
                const control = this.formData
                  .get(key).get(row).get(prop) as CustomFormControl;
                if (
                  control.def
                  && control.def.dontSubmit
                ) {
                  delete submitObject[key][row][prop];
                  emptyProps++;
                }
                else if (
                  !submitObject[key][row][prop]
                  || ['0.00', '0'].indexOf(submitObject[key][row][prop]) >= 0
                ) {
                  if (prop.startsWith('translations')) {
                    // remove empty translations properties from submit structure
                    delete submitObject[key][row][prop];

                  }
                  emptyProps++;
                } else if (this.hasTranslatable) {
                  if (prop.startsWith('translations')) {
                    if (typeof submitObject[key][row].translations === 'undefined') {
                      submitObject[key][row].translations = {};
                    }
                    const split = prop.split('-');
                    if (
                      typeof submitObject[key][row].translations[split[1]] === 'undefined'
                    ) {
                      submitObject[key][row].translations[split[1]] = {};
                    }
                    submitObject[key][row]
                      .translations[split[1]][split[2]] = submitObject[key][row][prop];
                    delete submitObject[key][row][prop];
                  }
                } else if (
                  // TODO check if field type is autocomplete
                  typeof submitObject[key][row][prop] === 'object'
                  && typeof submitObject[key][row][prop].value !== 'undefined'
                  && typeof submitObject[key][row][prop].text !== 'undefined'
                ) {
                  submitObject[key][row][prop] = submitObject[key][row][prop].value;
                }
              }
            }
            if (emptyProps === Object.keys(submitObject[key][row]).length) {
              // if all row properties have empty values, remove it
              submitObject[key].splice(row, 1);
            }
          }
        }
      } else if (
        typeof submitObject[key] === 'object'
        && submitObject[key] !== null
      ) {
        if (typeof submitObject[key].value !== 'undefined') {
          submitObject[key] = submitObject[key].value;
        } else {
          // if form group with controls
          let result = [];
          Object.keys(submitObject[key]).forEach((k) => {
            const control = this.formData.get(key).get(k) as CustomFormControl;
            if (control !== null) {
              if (control.def) {
                // if control def defines a custom data structure to submit
                if (control.def.submits) {
                  let submits = JSON.stringify(control.def.submits);
                  let val;
                  if (submitObject[key][k] !== '') {
                    val = JSON.stringify(submitObject[key][k]);
                  } else {
                    delete submitObject[key][k];
                  }
                  if (typeof val !== 'undefined') {
                    // if the property contains only {value}
                    submits = submits.replace(/\"\{value\}\"/g, val);
                    // if the property contains not only {value}
                    submits = submits.replace(/\{value\}/g, val);
                    if (control.def.flattenGroup) {
                      result = result.concat(JSON.parse(submits));
                    } else {
                      submitObject[key][k] = JSON.parse(submits);
                    }
                  }
                } else if (control.def.dontSubmit) {
                  delete submitObject[key][k];
                }
              } else if (
                typeof control.order !== 'undefined'
                && control.order !== null
                && typeof submitObject[key][k] !== 'undefined'
              ) {
                // if control has order index pass it with the selected value(s)
                submitObject[key][k] = {
                  order: control.order,
                  value: submitObject[key][k]
                };
              }
            }
          });
          if (result.length > 0) {
            submitObject[key] = result;
          }
        }
      } else {
        const control = this.formData.get(key) as CustomFormControl;
        if (control.def && control.def.dontSubmit) {
          delete (submitObject[key]);
        }
      }
      if (this.hasTranslatable) {
        if (key.startsWith('translations')) {
          if (this.formData.value[key]) {
            if (typeof submitObject.translations === 'undefined') {
              submitObject.translations = {};
            }
            const split = key.split('-');
            if (typeof submitObject.translations[split[1]] === 'undefined') {
              submitObject.translations[split[1]] = {};
            }
            submitObject.translations[split[1]][split[2]] = this.formData.getRawValue()[key];
          }
          delete submitObject[key];
        }
      }
    });

    let params;
    if (typeof this.data.submit.params !== 'undefined') {
      params = { ...this.data.submit.params, ...submitObject };
    } else {
      params = submitObject;
    }
    // await this.addRecaptcha(params);
    try {
      // if api service found
      const dataService = this.api.getService(this.data.submit.service);
      dataService[this.data.submit.method](params)
        .pipe(take(1), catchError((error, caught) => {
          if (typeof this.data.submitError === 'function') {
            if (!this.data.submitErrorParams) {
              this.data.submitErrorParams = { ...error };
              this.data.submitErrorParams['payload'] = { ...params };
              this.data.submitErrorParams.event = 'submitError';
              if (this.data.dataObject) {
                this.data.submitErrorParams.dataObject = this.data.dataObject;
              }
            }

            this.data.submitErrorParams.target = event.target;

            const result = this.data.submitError(this.data.submitErrorParams);
            return of();
          };
          throw error;
        }))
        .subscribe((response: any) => {
          if (this.data.success) {
            if (this.data.success.execute) {
              this.injector.get<any>(executables[
                this.data.success.execute.service
              ])[this.data.success.execute.method](
                response,
                this.data.success.execute.params
              );
            }
            if (this.data.success.callParent) {
              this.parentComponent[this.data.success.callParent]();
            }
            if (this.data.success.redirect) {
              switch (this.data.success.redirect) {
                case 'lastUrl':
                  const lastUrl = this.session.get('lastUrl');
                  const parsedUrl = decodeURI(lastUrl[0]).split('?');
                  if (parsedUrl[1]) {
                    const queryParams = this.queryStrToObj(parsedUrl[1]);
                    this.router.navigate([parsedUrl[0]], { queryParams });
                  } else {
                    this.router.navigate([parsedUrl[0]]);
                  }
                  break;
                default:
                  // check for and replace parameter placeholder from the response
                  const responseParamsRegex = /{(.*?)}/g;
                  const responseParams = this.data.success.redirect
                    .match(responseParamsRegex);
                  let redirect = this.data.success.redirect;
                  if (responseParams) {
                    for (const placeholder of responseParams) {
                      const param = placeholder.replace('{', '').replace('}', '');
                      redirect = redirect.replace(placeholder, response.result[param]);
                    }
                  }
                  // TODO: check for and replace parameter placeholder from the url
                  // const urlParamsRegex = /:(.*?)?(\/|$)/g;
                  const urlParams: any = this.route.snapshot.params;

                  for (const key of Object.keys(urlParams)) {
                    if (typeof urlParams[key] !== 'undefined') {
                      const paramRgx: RegExp = new RegExp(`:${key}`);
                      redirect = redirect.replace(paramRgx, urlParams[key]);
                    }
                  }

                  redirect = redirect.replace(/\/:.+/, '');

                  this.router.navigate([redirect]);
                  break;
              }
            }
            if (this.data.success.message) {
              const config = new MatSnackBarConfig();
              config.horizontalPosition = 'right';
              config.verticalPosition = 'top';
              config.panelClass = ['alert', 'alert-success'];
              if (this.data.success.duration) {
                config.duration = this.data.success.duration;
              }
              config.data = { messages: [this.data.success.message] };
              this.zone.run(() => this.alert.openFromComponent(ToastComponent, config));
            }
          }
          // execute submitSuccess event handler if form is in action
          if (typeof this.data.submitSuccess === 'function') {
            if (!this.data.submitSuccessParams) {
              this.data.submitSuccessParams = { ...response.result };
              this.data.submitSuccessParams['payload'] = { ...params };
              this.data.submitSuccessParams.event = 'submitSuccess';
              if (this.data.dataObject) {
                this.data.submitSuccessParams.dataObject = this.data.dataObject;
              }
            }

            this.data.submitSuccessParams.target = event?.target;

            const result = this.data.submitSuccess(this.data.submitSuccessParams);

            if (result instanceof Observable) {
              result.pipe(take(1)).subscribe(() => {
                if (this.data.resetOnSubmit) {
                  this.resetForm();
                }
              });
            } else {
              if (this.data.resetOnSubmit) {
                this.resetForm();
              }
            }
          }
        });
    } catch (error) {
      // if api service is not found, try to find an executable one
      this.injector.get<any>(executables[
        this.data.submit.service
      ])[this.data.submit.method](params);
    }
  }

  public getRowHeight(row: GridRow) {
    if (typeof row.height !== 'undefined') {
      const height = row.height.join('');
      return height;
    }
  }

  public printError(error, label) {
    let params = {};
    if (typeof error.value === 'object') {
      params = error.value;
    } else {
      params[error.key] = error.value;
    }
    return this.language.getLabel(label, params);
  }

  public areControlsExisting(field: FormField, formGroup: UntypedFormGroup) {
    if (field.translatable) {
      let existing = 0;
      for (const lang of this.languages) {
        const fieldName = 'translations-' + lang + '-' + field.name;
        if (formGroup.get(fieldName)) {
          existing++;
        }
      }
      return existing === this.languages.length;
    } else {
      return !!formGroup.get(field.name);
    }
  }

  drop(event: CdkDragDrop<string[]>, row: GridRow) {
    if (row.draggable && row.formArray) {
      const formArray = this.formData.get(row.formArray) as UntypedFormArray;
      moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
    }
  }
  extractedFieldDrop(event: CdkDragDrop<string[]>, field: ExtractedFormField) {
    if (typeof field.formGroup !== 'undefined') {
      const formGroup = this.formData.get(field.formGroup) as UntypedFormGroup;
      const controls = formGroup.controls as { [key: number]: CustomFormControl; };
      for (const i in controls) {
        if (event.previousIndex > event.currentIndex) {
          // moving up
          if (controls[i].order === event.previousIndex) {
            controls[i].order = event.currentIndex;
            // source item
          } else if (
            controls[i].order >= event.currentIndex
            && controls[i].order < event.previousIndex
          ) {
            // target item
            controls[i].order++;
          }
        } else {
          // moving down
          if (controls[i].order === event.previousIndex) {
            controls[i].order = event.currentIndex;
            // source item
          } else if (
            controls[i].order > event.previousIndex
            && controls[i].order <= event.currentIndex
          ) {
            // target item
            controls[i].order--;
          }
        }
      }
      moveItemInArray(
        this.extractedFields[event.item.data.id],
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public resetForm(): void {
    const resetFormData: object = {};

    this.rows.forEach(row => {
      row.cols.forEach(col => {
        col.fields.forEach(field => {
          if (typeof field.valuePath !== 'undefined') {
            resetFormData[field.name] = this.getArrayPathService.get(this.data.dataObject, field.valuePath);
          }
        });
      });
    });

    this.formData.reset(resetFormData);
    this.data.submitSuccessParams = null;
  }

  public removeControl(controlPath: string[]) {
    const clonedPath = [...controlPath];
    const controlName = clonedPath.pop();
    const formGroup = this.getControlByPath.get(
      this.formData,
      clonedPath,
      false
    ) as UntypedFormGroup;

    formGroup.removeControl(controlName);
  }

  public isFieldVisible(field, index): boolean {
    if (typeof field.showFieldConditions === 'undefined') {
      return true;
    }

    let conditions: Condition[] = [];
    if (!Array.isArray(field.showFieldConditions)) {
      conditions.push(field.showFieldConditions);
    } else {
      conditions = field.showFieldConditions;
    }

    const stack: boolean[] = [];

    const conditionScope = this.getControlByPath.get(this.formData, '');

    for (const condition of conditions) {
      if (condition.op === 'isArrayIndexGreater') {
        if (index >= condition.value) {
          stack.push(true);
        } else {
          stack.push(false);
        }
      } else if (this.evaluator.exec(conditionScope, condition)) {
        stack.push(true);
      } else {
        stack.push(false);
      }
    }

    if (stack.indexOf(false) < 0) {
      return true;
    } else {
      return false;
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
