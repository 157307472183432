import { NgModule, ErrorHandler, APP_INITIALIZER, CompilerFactory, COMPILER_OPTIONS, Compiler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { VexSdkModule } from 'ng-vex-sdk';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import LocaleBG from '@angular/common/locales/bg';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import {
   LayoutsModule,
   ApiService,
   QueryPipesModule,
   SlotDialogModule,
   LoaderModule,
   LoaderInterceptor,
   PermissionsService,
   LanguageService,
   PrimitiveModule,
   ToastModule,
   AlertDialogModule,
   ErrorService,
   RoutingService,
   SlotComponent
} from 'ng-viv-ux';

registerLocaleData(LocaleBG);


export function initApp(
   routing: RoutingService,
   permissions: PermissionsService,
   language: LanguageService,
   api: ApiService
) {
   return (): Promise<any> => {
      return api.loadSdk(environment).then(() => {
         return language.getLanguages().then(() => {
            return permissions.checkSession().then(() => {
               return routing.generateRoutes();
            });
         });
      });
   };
}

export function createCompiler(compilerFactory: CompilerFactory) {
   return compilerFactory.createCompiler();
}
@NgModule({
   declarations: [
      AppComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      VexSdkModule,
      AppRoutingModule,
      PrimitiveModule,
      LayoutsModule,
      AlertDialogModule,
      SlotDialogModule,
      LoaderModule,
      ToastModule,
      QueryPipesModule,
      RecaptchaV3Module
   ],
   providers: [
      RoutingService,
      LanguageService,
      PermissionsService,
      CurrencyPipe,
      DecimalPipe,
      {
         provide: APP_INITIALIZER,
         useFactory: initApp,
         deps: [
            RoutingService,
            PermissionsService,
            LanguageService,
            ApiService
         ],
         multi: true
      },
      {
         provide: COMPILER_OPTIONS,
         useValue: {},
         multi: true
      },
      {
         provide: CompilerFactory,
         useClass: JitCompilerFactory,
         deps: [COMPILER_OPTIONS]
      },
      {
         provide: Compiler,
         useFactory: createCompiler,
         deps: [CompilerFactory]
      },
      {
         provide: ErrorHandler,
         useClass: ErrorService
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoaderInterceptor,
         multi: true
      },
      {
         provide: RECAPTCHA_V3_SITE_KEY,
         useValue: environment.recaptchaApiToken
      }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule {
   constructor() {
      // register custom components
      SlotComponent.registry['LoginComponent'] = {
         loadChildren: () =>
            import('./login/login.module')
               .then(m => m.LoginModule)
      };

      SlotComponent.registry['MainMenuComponent'] = {
        loadChildren: () =>
          import('./main-menu/main-menu.module')
            .then(m => m.MainMenuModule)
      };
   }
}
