import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../backbone/api.service';
import { CommunicationService, Message } from '../../backbone/communication.service';
import { GetArrayPathService } from '../../backbone/get-array-path.service';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent implements OnInit, ISlotComponent, OnDestroy {
  @ViewChildren(MatExpansionPanel) public allPanels: QueryList<MatExpansionPanel>;
  @Input() data: any;

  public selfRef: AccordionComponent;

  private dataService;
  private destroyed: Subject<void> = new Subject();

  constructor(private api: ApiService,
              private comm: CommunicationService,
              private arrayPathService: GetArrayPathService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.selfRef = this;

    if (typeof this.data.channel !== 'undefined') {
      this.comm.getChannel(this.data.channel)
        .pipe(takeUntil(this.destroyed))
        .subscribe((message: Message) => {
          this.comm.processMessage(message, this);

          // detectChanges must be called for the accordion to be rerendered,
          // because the component uses onPush change detection strategy
          this.cdr.detectChanges();
        });
    }

    this.load();
  }

  public isExpanded(i: number) {
    if (this.allPanels) {
      return this.allPanels.get(i).expanded;
    } else {
      return this.data.expanded;
    }
  }

  public load(): void {
    let params: any = {};

    if (typeof this.data.dataSource === 'undefined') {
      return;
    }

    if (typeof this.data.dataSource.params !== 'undefined') {
      params = { ...this.data.dataSource.params };
    }

    this.api.callServiceMethod(this.data.dataSource)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.data.dataObject = response.result.data;

        // detectChanges must be called for the accordion to be rerendered,
        // because the component uses onPush change detection strategy
        this.cdr.detectChanges();
      });
  }

  public removePanel(id: number) {
    const allPanels = this.arrayPathService.get(this.data.dataObject, this.data.path);
    const index = allPanels.findIndex(panel => {
      return panel.id === id;
    });

    allPanels.splice(index, 1);
  }

  public addRule(newRule: any) {
    const allPanels = this.arrayPathService.get(this.data.dataObject, this.data.path);
    const index = allPanels.findIndex(panel => {
      return panel.id === newRule.carrier_id;
    });

    if (newRule.default) {
      allPanels[index].rules.forEach((rule, idx, arr) => {
        arr[idx].default = false;
      });
    }

    allPanels[index].rules.push(newRule);
    this.data.dataObject[index] = {...this.data.dataObject[index]};
  }

  public updateRule(updatedRule: any) {
    const allPanels = this.arrayPathService.get(this.data.dataObject, this.data.path);
    const index = allPanels.findIndex(panel => {
      return panel.id === updatedRule.carrier_id;
    });

    const ruleIndex = allPanels[index].rules.findIndex(rule => {
      return rule.id === updatedRule.id;
    });

    allPanels[index].rules[ruleIndex] = updatedRule;
  }

  public removeRule(deletedRule: any) {
    const allPanels = this.arrayPathService.get(this.data.dataObject, this.data.path);
    const index = allPanels.findIndex(panel => {
      return panel.id === deletedRule.carrier_id;
    });

    const ruleIndex = allPanels[index].rules.findIndex(rule => {
      return rule.id === deletedRule.id;
    });

    allPanels[index].rules.splice(ruleIndex, 1);
  }

  public ngOnDestroy(): void {
      this.destroyed.next();
      this.destroyed.complete();
  }
}
