import { Injectable } from '@angular/core';
import { Mutation, ApiCallService, Batch } from '../api-call.service';


export interface ListPricelistParams {
  with: string[];
  mutations: Mutation[];
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})
export class PricingPricelistService {

  constructor(private api: ApiCallService) { }

  public list(params?: ListPricelistParams, page?) {
    return this.api.call('pricing.pricelist.list', params, page);
  }
}
