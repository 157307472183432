import { Injectable } from '@angular/core';
import { Condition } from './interfaces/condition.interface';
import { GetArrayPathPipe } from './pipes/get-array-path.pipe';

@Injectable({
  providedIn: 'root'
})
export class EvalService {

  private fn = {
    '===': (a, b) => {
      if (typeof a === 'object' && typeof b === 'object') {
        return JSON.stringify(a) === JSON.stringify(b);
      } else {
        return a === b;
      }
    },
    '!==': (a, b) => {
      if (typeof a === 'object' && typeof b === 'object') {
        return JSON.stringify(a) !== JSON.stringify(b);
      } else {
        return a !== b;
      }
    },
    '<': (a, b) => a < b,
    '>': (a, b) => a > b,
    '<=': (a, b) => a <= b,
    '>=': (a, b) => a >= b,
    between: (a, b) => a >= b[0] && a <= b[1],
    typeof: (a, b) => typeof a === b,
    in: (a, b) => b.indexOf(a) >= 0,
    keyExists: (a, b) => {
      if (typeof a === 'undefined') {
        return false;
      }
      if (b.indexOf('*') < 0) {
        return typeof a[b] !== 'undefined' && a[b] !== null;
      } else {
        for (const k in a) {
          if (k.startsWith(b.substring(0, b.indexOf('*')))) {
            return true;
          }
        }
        return false;
      }
    },
    keyDoesntExist: (a, b) => {
      if (typeof a === 'undefined') {
        return true;
      }
      return typeof a[b] === 'undefined' || a[b] === null
    },
    isObjectNotEmpty: (a, b) => {
      return typeof a[b] !== 'undefined' &&
        typeof a[b] !== null &&
        a[b] !== null &&
        Object.keys(a[b]).length > 0;
    },
    startsWith: (a, b) => a.startsWith(b),
    isCountGt: (a, b) => {
      return Array.isArray(a) && a.length > b;
    },
    isCountGte: (a, b) => {
      return Array.isArray(a) && a.length >= b;
    },
    isCountLte: (a, b) => {
      return Array.isArray(a) && a.length <= b;
    },
    isCountLt: (a, b) => {
      return Array.isArray(a) && a.length < b;
    },
    isOlderThan: (a, b) => {
      a = new Date(a);
      const now = new Date();
      const interval = '-' + b;
      b = this.modifyDate(now, interval);
      return a.getTime() < b.getTime();
    },
    isNewerThan: (a, b) => {
      a = new Date(a);
      const now = new Date();
      const interval = '-' + b;
      b = this.modifyDate(now, interval);
      return a.getTime() >= b.getTime();
    }
  };

  constructor(private getArrayPath: GetArrayPathPipe) { }

  exec(data, conditions: Condition | Condition[]): boolean {
    if (Array.isArray(conditions)) {
      const stack = [];
      for (const idx in conditions) {
        if (Array.isArray(data)) {
          stack.push(this.evalCondition(data[idx], conditions[idx]));
        } else {
          stack.push(this.evalCondition(data, conditions[idx]));
        }
      }
      if (stack.indexOf(false) >= 0) {
        return false;
      }
      return true;
    } else {
      return this.evalCondition(data, conditions);
    }
  }
  private evalCondition(data, condition: Condition) {
    if (typeof condition.any === 'undefined') {
      condition.any = false;
    }
    const a = this.getArrayPath.transform(data, condition.path);
    let b;
    if (Array.isArray(condition.value) && ['in', 'between'].indexOf(condition.op) === -1) {
      b = this.getArrayPath.transform(data, condition.value);
    } else {
      if (condition.value === 'null') {
        b = null;
      } else {
        b = condition.value;
      }
    }

    let result: boolean;
    if (
      Array.isArray(a) &&
      !condition.op.startsWith('isCount') &&
      !condition.op.startsWith('keyExists')
    ) {
      const stack = [];
      for (const item of a) {
        stack.push(this.fn[condition.op](item, b));
      }
      if (condition.any) {
        result = false;
        if (stack.indexOf(true) >= 0) {
          result = true;
        }
      } else {
        result = true;
        if (stack.indexOf(false) >= 0) {
          result = false;
        }
      }
    } else {
      result = this.fn[condition.op](a, b);
    }
    return result;
  }
  private modifyDate(date: Date, offset: string): Date {
    const split = offset.split(' ');

    const capitalizedUnit = split[1].charAt(0).toUpperCase() + split[1].slice(1);
    const getter = 'get' + capitalizedUnit;
    const setter = 'set' + capitalizedUnit;

    const value = parseInt(split[0]);

    date[setter](date[getter]() + value);
    return date;
  }
}
