import { Injectable } from '@angular/core';
import { ApiCallService, Batch } from '../api-call.service';

interface LocaleParams {
  id: number;
  batch?: Batch;
}

interface CreateLocaleParams extends LocaleParams {
  locale: string;
  name: string;
  status: number;
  default: number;
  [key: string]: any;
}
interface UpdateLocaleParams extends LocaleParams {
  name?: string;
  status?: number;
  default?: number;
  [key: string]: any;
}

interface ChangeLocaleParams {
  locale: string;
}

interface LocalesListParams {
  batch?: Batch;
}

@Injectable({
  providedIn: 'root'
})

export class LocaleService {
  constructor(private api: ApiCallService) { }

  public list(params?: LocalesListParams, page?: number) {
    return this.api.call('app.locale.list', undefined, page);
  }

  public getLocales(params?: { batch?: Batch }) {
    return this.api.call('app.locale.getLocales');
  }

  public create(params: CreateLocaleParams) {
    return this.api.call('app.locale.store', params);
  }

  public view(params: LocaleParams) {
    return this.api.call('app.locale.view', params);
  }

  public update(params: UpdateLocaleParams) {
    return this.api.call('app.locale.update', params);
  }
  public change(params: ChangeLocaleParams) {
    return this.api.call('app.locale.change', params);
  }

  public delete(params: LocaleParams) {
    return this.api.call('app.locale.delete', params);
  }
}
