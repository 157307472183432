import { Component, Input, OnInit, OnDestroy, NgZone} from '@angular/core';
import { PromptMomentNotification } from 'google-one-tap';
import { Subject } from 'rxjs';
import { ApiService } from '../../backbone/api.service';
import { LoginService } from '../../backbone/executables';
import { LanguageService } from '../../backbone/language.service';
import { ISlotComponent } from '../slot/slot-component';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss']
})
export class GoogleLoginComponent implements OnInit, ISlotComponent, OnDestroy {

  @Input() public data: any = {};
  myScriptElement: HTMLScriptElement;
  private tokenClient: any;
  private destroyed = new Subject<void>();

  constructor(
    public language: LanguageService,
    private loginService: LoginService,
    protected api: ApiService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    const signInJS = document.createElement('script');
    signInJS.async = true;
    signInJS.src = 'https://accounts.google.com/gsi/client';
    signInJS.onload = () => {
      google.accounts.id.initialize({
        client_id: this.api.getEnv().googleLoginConfig.appId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
        prompt_parent_id: 'google-button',
        itp_support: true
      });

      
      google.accounts.id.renderButton(
        
        document.getElementById('google-button'),
        {
          theme: this.data.buttonData.theme, //filled_black, filled_blue, outline
          type: this.data.buttonData.type, //icon, standard
          shape: this.data.buttonData.shape, // pill, rectangular, square (if type icon)
          size: this.data.buttonData.size, //medium, small
          width: this.data.buttonData.width, //define width in px or percent
          logo_alignment: this.data.buttonData.logo_alignment, // right,
          text: this.data.buttonData.text,  //signin_with, signup_with, signin, continue_with
        }
      );
      
      google.accounts.id.prompt((notification: PromptMomentNotification) => {
        // console.log(notification);
      });

      
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: this.api.getEnv().googleLoginConfig.appId,
        scope: 'email',
        prompt: '',
        callback: (tokenResponse) => {
            if (!tokenResponse.error) {
              this.zone.run(() => this.login(tokenResponse));
            } else {
              console.log(tokenResponse);
            }
        },
      });
    };
    document.head.appendChild(signInJS);
  }

  async handleCredentialResponse(response: any) {
    // Login response from Google.
    this.tokenClient.requestAccessToken();
  }

  login(response: any) {
    const dataService = this.api.getService(this.data.dataSource.service);
    dataService[this.data.dataSource.method]({authToken: response.access_token})
    .subscribe((result: any) => {
      this.loginService.login(result, {
        redirect: this.data.redirectTo
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
